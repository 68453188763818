module.exports = {
  siteTitle: 'Praegrandis Constructions', // Site title.
  siteTitleAlt: 'Praegrandis Constructions', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://clever-jackson-25e4b2.netlify.app', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'Praegrandis Constructions Official Website', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  disqusShortname: 'Praegrandis Constructions', // Disqus shortname.
  userName: 'praegrandis',
  userTwitter: 'praegrandis',
  userLocation: 'Sydney, Australia',
  userDescription: '',
  copyright: 'Copyright © Praegrandis Constructions 2020. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#B37E40', // Used for setting manifest and progress theme colors.
  backgroundColor: '#f4f4f4', // Used for setting manifest background color.
  cookieConsent: 'This website uses cookies which are used to collect anonymous information to improve your browsing experience and for analytics and metrics.', // @TODO Add GDPR Cookie Consent
}
